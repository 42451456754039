import { useState } from "react";

import CarModal from "./CarModal";
import Images from "./CarImages";

import styles from "./CarDisplay.module.css";

const CarDisplay = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCar, setSelectedCar] = useState({
    carName: "",
    carPrice: "",
  });

  const toggleModal = (): void => {
    setShowModal(!showModal);
  };

  const handleCarSelection = (carName: string, carPrice: string): void => {
    setSelectedCar({ carName, carPrice });
    toggleModal();
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.card}
        onClick={() => handleCarSelection("Audi A5 Sportback", "34,800€")}
      >
        <img
          src={Images["Audi A5 Sportback"][0]}
          alt="Audi A5 Sportback"
          className={styles.image}
        />
        <div className={styles.details}>
          <h2>Audi A5 Sportback</h2>
          <p>Preço: 34,800€</p>
        </div>
      </div>
      <div
        className={styles.card}
        onClick={() => handleCarSelection("Ford Focus", "12,000€")}
      >
        <img
          src={Images["Ford Focus"][0]}
          alt="Ford Focus"
          className={styles.image}
        />
        <div className={styles.details}>
          <h2>Ford Focus SW Titanium</h2>
          <p>Preço: 11,200€</p>
        </div>
      </div>
      <div
        className={styles.card}
        onClick={() => handleCarSelection("Renault Talisman", "17,000€")}
      >
        <img
          src={Images["Renault Talisman"][0]}
          alt="Renault Talisman"
          className={styles.image}
        />
        <div className={styles.details}>
          <h2>Renault Talisman</h2>
          <p>Preço: 17,000€</p>
        </div>
      </div>
      <div
        className={styles.card}
        onClick={() => handleCarSelection("Citroen C4", "5,000€")}
      >
        <img
          src={Images["Citroen C4"][0]}
          alt="Citroen C4"
          className={styles.image}
        />
        <div className={styles.details}>
          <h2>Citroen C4</h2>
          <p>Preço: 4,500€</p>
        </div>
      </div>
      <div
        className={styles.card}
        onClick={() => handleCarSelection("Atrelado", "3,300€")}
      >
        <img
          src={Images["Atrelado"][0]}
          alt="Atrelado"
          className={styles.image}
        />
        <div className={styles.details}>
          <h2>Atrelado</h2>
          <p>Preço: 3,300€</p>
        </div>
      </div>
      <CarModal
        showModal={showModal}
        toggleModal={toggleModal}
        carName={selectedCar.carName || ""}
        carPrice={selectedCar.carPrice || ""}
        images={selectedCar ? Images[selectedCar.carName] : []}
      />
    </div>
  );
};

export default CarDisplay;
