import Header from "./components/Header";
import CarDisplay from "./components/CarDisplay";
import Footer from "./components/Footer";

import "./App.css";

const App = () => (
  <div className="App">
    <Header />
    <main className="mainStyle">
      <CarDisplay />
    </main>
    <Footer />
  </div>
);

export default App;
