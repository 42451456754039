import { useState, useEffect } from "react";

import styles from "./CarModal.module.css";

interface CarModalProps {
  showModal: boolean;
  toggleModal: () => void;
  carName: string;
  carPrice: string;
  images: string[];
}

const CarModal = ({
  showModal,
  toggleModal,
  carName,
  carPrice,
  images,
}: CarModalProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  useEffect(() => {
    if (showModal) {
      setCurrentImageIndex(0);
    }
  }, [showModal]);

  const handlePrevImage = (): void => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNextImage = (): void => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  if (!showModal) return null;

  return (
    <div className={styles.modalBackdrop} onClick={toggleModal}>
      <div className={styles.modalContent} onClick={stopPropagation}>
        <button className={styles.closeButton} onClick={toggleModal}>
          &times;
        </button>

        <h2>{carName}</h2>
        <div style={{ position: "relative" }}>
          <div
            onClick={handlePrevImage}
            className={`${styles.arrow} ${styles["arrow-left"]}`}
          >
            &lt;
          </div>
          <div
            onClick={handleNextImage}
            className={`${styles.arrow} ${styles["arrow-right"]}`}
          >
            &gt;
          </div>
          <img
            src={images[currentImageIndex]}
            alt={carName}
            className={styles.modalImage}
          />
        </div>
        <p>Modelo: {carName}</p>
        <p>Preço: {carPrice}</p>
      </div>
    </div>
  );
};

export default CarModal;
