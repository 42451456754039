import logo from "../statics/logo.png";
import styles from "./Header.module.css";

const Header = () => (
  <header className={styles.header}>
    <img src={logo} alt="MCAuto Logo" className={styles.logo} />
  </header>
);

export default Header;
