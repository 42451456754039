import styles from "./Footer.module.css";

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.content}>
      <div className={styles.contactInfo}>
        <p>Contactos: 912 402 168 | mcautourem@gmail.com</p>
      </div>

      <div className={styles.address}>
        <p>Visite-nos em: Av. Dom Nuno Álvares Pereira 6, Ourém, Portugal</p>
      </div>
    </div>
  </footer>
);

export default Footer;
