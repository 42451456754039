const carImages: Record<string, string[]> = {
  "Audi A5 Sportback": [
    "https://i.imgur.com/llYcme9.jpeg",
    "https://i.imgur.com/2GnAoLx.jpeg",
    "https://i.imgur.com/w9p87pP.jpeg",
    "https://i.imgur.com/KxAFtG5.jpeg",
    "https://i.imgur.com/1Wq0XZl.jpeg",
  ],
  "Ford Focus": [
    "https://imgur.com/w1ymiLh.jpeg",
    "https://imgur.com/5JlN3RN.jpeg",
    "https://imgur.com/t8ZfaWR.jpeg",
    "https://imgur.com/5F1r12z.jpeg",
    "https://imgur.com/6nCEflF.jpeg",
    "https://imgur.com/Hvz41yl.jpeg",
  ],
  "Renault Talisman": [
    "https://i.imgur.com/g5npFTt.jpeg",
    "https://i.imgur.com/GBFVx6t.jpeg",
    "https://i.imgur.com/4X0QYc3.jpeg",
    "https://i.imgur.com/8UFMVtv.jpeg",
    "https://i.imgur.com/5yjxWjN.jpeg",
    "https://i.imgur.com/G13fGLY.jpeg",
    "https://i.imgur.com/HhRVDAI.jpeg",
  ],
  "Citroen C4": [
    "https://i.imgur.com/PvCoHP2.jpeg",
    "https://i.imgur.com/vpc2AVy.jpg",
    "https://i.imgur.com/plaIKQN.jpg",
    "https://i.imgur.com/rYNftWj.jpg",
    "https://i.imgur.com/jzwNJY9.jpg",
  ],
  Atrelado: [
    "https://imgur.com/dPIri1W.jpeg",
    "https://imgur.com/uame3CU.jpeg",
    "https://imgur.com/9LXfvdK.jpeg",
    "https://imgur.com/Je9Kzo0.jpeg",
    "https://imgur.com/r2DPiWa.jpeg",
  ],
};

export default carImages;
